<template>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
    <div class="container-fluid shadow-sm">
      <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
        <a
          @click="back"
          class="btn p-0 mr-md-4 text-light h5 mb-0"
        >
          <i class="fas fa-arrow-left"></i>
        </a>
        <span class="navbar-brand bg-transparent rounded p-0 text-center mr-0 mr-md-5">
          <span class="h6 font-weight-bold text-truncate">Supplier Info</span>
        </span>
        <div class="text-nowrap order-md-last">
          <router-link :to="'/jual/supplier/edit/'+state.id" class="btn px-0 d-inline-block">
            <i class="fas fa-pencil-alt text-light"></i>
          </router-link>
        </div>
      </nav>
    </div><!-- .container -->
  </header>

  <div class="container-fluid mt-header" v-if="state.finish">
    <div class="row" id="body-sidemenu">
      <!-- MAIN -->
      <div id="main-content" class="col pb-5 with-fixed-sidebar">

        <div class="jumbotron jumbotron-fluid mb-0 shadow-sm no-side-margin bg-secondary">
          <div class="container text-center">
            <p class="h5 my-2 text-white">{{ state.data.name }}</p>
          </div>
        </div>
      
        <div class="row mt-3">
          <div class="col-12">
            <p class="mb-1 small text-muted">Alamat</p>
            <p class="mb-0">{{ state.data.alamat }}</p>
          </div><!-- .col -->
        </div><!-- .row -->

        <div class="row mt-3">
          <div class="col-6">
            <p class="mb-1 small text-muted">Telepon/WA</p>
            <p class="mb-0">{{ state.data.phone }}</p>
          </div><!-- .col -->
          <div class="col-6 text-right text-md-left">
            <p class="mb-1 small text-muted">Website</p>
            <p class="mb-0">{{ state.data.website }}</p>
          </div><!-- .col -->
        </div><!-- .row -->

        <div class="row mt-3">
          <div class="col-12">
            <p class="mb-1 small text-muted">Deskripsi</p>
            <p class="mb-0">{{ state.data.desc }}</p>
          </div><!-- .col -->
        </div><!-- .row -->

        <div class="row mt-3">
          <div class="col-12 px-0">
            <ul class="list-group rounded-0 mt-3">
              <li class="list-group-item d-flex justify-content-between align-items-center border-left-0 border-right-0 p-0">
                <a href="./jual-stok.html?supplierId=000001" class="py-2 pl-3 text-uppercase stretched-link">Stok Bahan Terkait</a>
                <i class="fa fa-caret-right mr-3"></i>
              </li>
            </ul>
          </div><!-- .col -->
        </div><!-- .row -->
  
      </div><!-- main-content -->
    </div><!-- .row -->
  </div><!-- .container -->
</template>
<script>
import { reactive, onMounted } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router';

export default {
  setup() {
    const route = useRoute();
    const state = reactive({
      data: {},
      finish: false,
      id: '0',
    })
    const loadData = async () => {
      state.finish = false;
      let id = route.params.id;
      await axios.get('/api/seller/supplier/'+id).then((res) => {
        if(res.data.success){
          state.data = res.data.data;
          state.finish = true;
        }
      })
    }
    onMounted(() => {
      state.id = route.params.id;
      loadData();
    })
    const back = () => {
      window.history.back();
    }
    return {
      state,
      back
    }
  },
}
</script>